
import { defineComponent } from 'vue';
import Hero from '@/components/Hero.vue';
import SectionSpacer from '@/components/SectionSpacer.vue';

export default defineComponent({
  name: 'Privacy',

  components: {
    Hero,
    SectionSpacer,
  },
});

<template>
  <Hero>
    <h1>
      Daten&shy;schutz&shy;erklärung
    </h1>
  </Hero>
  <section>
    <section-spacer :spacer="2" type="gap"></section-spacer>
    <div class="sp"></div>
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-9 col-xl-8">
          <h2 class="h3">
            1. Datenschutz auf einen Blick
          </h2>
          <h3 class="h4">
            Allgemeine Hinweise
          </h3>
          <p>
            Die folgenden Hinweise geben einen einfachen Überblick darüber,
            was mit Ihren personen&shy;bezogenen Daten passiert,
            wenn Sie diese Website besuchen. Personen&shy;bezogene Daten sind alle Daten,
            mit denen Sie persönlich identifiziert werden können.
            Ausführliche Informationen zum Thema Datenschutz entnehmen Sie
            unserer unter diesem Text aufgeführten Datenschutz&shy;erklärung.
          </p>
          <div class="sp m"></div>
          <h3 class="h4">
            Datenerfassung auf dieser Website
          </h3>
          <h4 class="h5">
            Wer ist verantwortlich für die Daten&shy;erfassung auf dieser Website?
          </h4>
          <p>
            Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber.
            Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“
            in dieser Daten&shy;schutz&shy;erklärung entnehmen.
          </p>
          <div class="sp s"></div>
          <h4 class="h5">
            Wie erfassen wir Ihre Daten?
          </h4>
          <p>
            Ihre Daten werden zum einen dadurch erhoben,
            dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln,
            die Sie in ein Kontakt&shy;formular eingeben.
          </p>
          <p>
            Andere Daten werden automatisch oder nach Ihrer Einwilligung
            beim Besuch der Website durch unsere ITSysteme erfasst.
            Das sind vor allem technische Daten (z. B. Internetbrowser,
            Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser
            Daten erfolgt automatisch, sobald Sie diese Website betreten.
          </p>
          <div class="sp s"></div>
          <h4 class="h5">
            Wofür nutzen wir Ihre Daten?
          </h4>
          <p>
            Ein Teil der Daten wird erhoben,
            um eine fehlerfreie Bereitstellung der Website zu gewährleisten.
            Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
          </p>
          <div class="sp s"></div>
          <h4 class="h5">
            Welche Rechte haben Sie bezüglich Ihrer Daten?
          </h4>
          <p>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten.
            Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten
            zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
            können Sie diese Einwilligung jederzeit für die Zukunft widerrufen.
            Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
            Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </p>
          <p>
            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können
            Sie sich jederzeit an uns wenden.
          </p>
          <div class="sp m"></div>
          <h3 class="h4">
            Analyse-Tools und Tools von Drittanbietern
          </h3>
          <p>
            Beim Besuch dieser Website kann Ihr Surf-Verhalten
            statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten
            Analyseprogrammen.
          </p>
          <p>
            Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden
            Datenschutzerklärung.
          </p>
          <div class="sp"></div>
          <h2 class="h3">
            2. Hosting
          </h2>
          <h3 class="h4">
            Externes Hosting
          </h3>
          <p>
            Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
            Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden
            auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um IP-Adressen,
            Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
            Websitezugriffe und sonstige Daten, die über eine Website generiert werden, handeln.
          </p>
          <p>
            Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren
            potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO)
            und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres
            Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
          </p>
          <p>
            Unser Hoster wird Ihre Daten nur insoweit verarbeiten,
            wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und
            unsere Weisungen in Bezug auf diese Daten befolgen.
          </p>
          <p>
            Wir setzen folgenden Hoster ein:<br><br>
            1&1 IONOS SE<br>
            Elgendorfer Str. 57<br>
            56410 Montabaur
          </p>
          <div class="sp"></div>
          <h2 class="h3">
            3. Allgemeine Hinweise und Pflichtinformationen
          </h2>
          <h3 class="h4">
            Datenschutz
          </h3>
          <p>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst.
            Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den
            gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
          </p>
          <p>
            Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
            Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert
            werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir
            erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck
            das geschieht.
          </p>
          <p>
            Wir weisen darauf hin, dass die Datenübertragung im Internet
            (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
          </p>
          <div class="sp s"></div>
          <h4 class="h5">
            Hinweis zur verantwortlichen Stelle
          </h4>
          <p>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:<br><br>
            Paul Zimmermann<br>
            Gertrud-Bäumer-Straße 9<br>
            44534 Lünen<br><br>
            Telefon: <a href="tel:+4923063096032">023063096032</a><br>
            E-Mail: <a href="mailto:contact@milkycheck.com">contact@milkycheck.com</a>
          </p>
          <p>
            Verantwortliche Stelle ist die natürliche oder juristische Person, die allein
            oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
            personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
          </p>
          <div class="sp s"></div>
          <h4 class="h5">
            Speicherdauer
          </h4>
          <p>
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt
            wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die
            Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen
            oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht,
            sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
            personenbezogenen Daten haben (z. B. steuer- oder
            handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt
            die Löschung nach Fortfall dieser Gründe.
          </p>
          <div class="sp s"></div>
          <h4 class="h5">
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </h4>
          <p>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung
            möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen.
            Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
            Widerruf unberührt.
          </p>
          <div class="sp s"></div>
          <h4 class="h5">
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
            Direktwerbung (Art. 21 DSGVO)
          </h4>
          <p>
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
            ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
            SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
            WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
            PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
            ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN,
            WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
            SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
            NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
            VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
            RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
          </p>
          <p>
            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
            SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
            BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
            EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
            VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
            ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
            NACH ART. 21 ABS. 2 DSGVO).
          </p>
          <div class="sp s"></div>
          <h4 class="h5">
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </h4>
          <p>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht
            bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen
            Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu.
            Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher
            oder gerichtlicher Rechtsbehelfe.
          </p>
          <div class="sp s"></div>
          <h4 class="h5">
            Recht auf Datenübertragbarkeit
          </h4>
          <p>
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in
            Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten
            in einem gängigen, maschinenlesbaren Format aushändigen zu lassen.
            Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
            verlangen, erfolgt dies nur, soweit es technisch machbar ist.
          </p>
          <div class="sp s"></div>
          <h4 class="h5">
            SSL- bzw. TLS-Verschlüsselung
          </h4>
          <p>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
            vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns
            als Seitenbetreiber senden, eine SSL- bzw. TLSVerschlüsselung. Eine verschlüsselte
            Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf
            „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
          </p>
          <p>
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an
            uns übermitteln, nicht von Dritten mitgelesen werden.
          </p>
          <div class="sp s"></div>
          <h4 class="h5">
            Auskunft, Löschung und Berichtigung
          </h4>
          <p>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht
            auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten,
            deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein
            Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen
            zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
          </p>
          <div class="sp s"></div>
          <h4 class="h5">
            Recht auf Einschränkung der Verarbeitung
          </h4>
          <p>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
            zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf
            Einschränkung der Verarbeitung besteht in folgenden Fällen:
          </p>
          <ul class="fa-ul">
            <li>
              <span class="fa-li"><i class="far fa-dot-circle"></i></span>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten
              bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer
              der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              <span class="fa-li"><i class="far fa-dot-circle"></i></span>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
              geschah/geschieht, können Sie statt der Löschung die Einschränkung der
              Datenverarbeitung verlangen.
            </li>
            <li>
              <span class="fa-li"><i class="far fa-dot-circle"></i></span>
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur
              Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen,
              haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </li>
            <li>
              <span class="fa-li"><i class="far fa-dot-circle"></i></span>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine
              Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch
              nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die
              Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </li>
          </ul>
          <p>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen
            diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines
            wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats
            verarbeitet werden.
          </p>
          <div class="sp"></div>
          <h2 class="h3">
            4. Datenerfassung auf dieser Website
          </h2>
          <h3 class="h4">
            Cookies
          </h3>
          <p>
            Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Textdateien
            und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend
            für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf
            Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch
            gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese
            selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.
          </p>
          <p>
            Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert
            werden, wenn Sie unsere Seite betreten (Third-Party-Cookies). Diese ermöglichen uns oder
            Ihnen die Nutzung bestimmter Dienstleistungen des Drittunternehmens (z. B. Cookies zur
            Abwicklung von Zahlungsdienstleistungen).
          </p>
          <p>
            Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig,
            da bestimmte Websitefunktionen ohne diese nicht funktionieren würden (z. B. die
            Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies dienen dazu, das
            Nutzerverhalten auszuwerten oder Werbung anzuzeigen.
          </p>
          <p>
            Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs (notwendige
            Cookies) oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen
            (funktionale Cookies, z. B. für die Warenkorbfunktion) oder zur Optimierung der
            Website (z. B. Cookies zur Messung des Webpublikums) erforderlich sind, werden auf
            Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
            Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an
            der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung
            seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies abgefragt wurde,
            erfolgt die Speicherung der betreffenden Cookies ausschließlich auf Grundlage dieser
            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.
          </p>
          <p>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert
            werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte
            Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die
            Funktionalität dieser Website eingeschränkt sein.
          </p>
          <p>
            Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden,
            werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung gesondert informieren
            und ggf. eine Einwilligung abfragen.
          </p>
          <div class="sp m"></div>
          <h3 class="h4">
            Kontaktformular
          </h3>
          <p>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
            Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung
            der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben
            wir nicht ohne Ihre Einwilligung weiter.
          </p>
          <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO,
            sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur
            Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen
            beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung
            der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung
            (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
          </p>
          <p>
            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur
            Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die
            Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
            Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben
            unberührt.
          </p>
          <div class="sp"></div>
          <small>
            Quelle: <a href="https://www.e-recht24.de" target="_blank">eRecht24</a>
          </small>
        </div>
      </div>
    </div>
    <div class="sp"></div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Hero from '@/components/Hero.vue';
import SectionSpacer from '@/components/SectionSpacer.vue';

export default defineComponent({
  name: 'Privacy',

  components: {
    Hero,
    SectionSpacer,
  },
});
</script>

<style scoped>

</style>
